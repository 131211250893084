#certifications-header-navbar-toogle {
    width: 25rem;
    height: 3rem;
    border-radius: 18px;
    background-color: rgba(0,0,0,.5);
}

.certifications-navbar-link {
    width: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.link {
    color: rgb(154, 154, 154);
    padding: 5px;
}

.certifications-navbar-link.selected .link,
.certifications-navbar-link:hover .link,
.certifications-navbar-link:focus .link {
    color: white;
}

.certifications-navbar-link.selected .link {
    border-radius: 18px;
}

.achievements-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.ach-card {
    position: relative;
    width: 20rem;
    height: 12rem;
    background-color: rgba(0,0,0,.5);
    border-radius: 18px;
}

.ach-card.not-done {
    filter: brightness(.5);
}

.light-mode .ach-card.not-done {
    filter: brightness(.7);
}

.ach-card.not-done img{
    filter:  brightness(0) invert(1);
}

.light-mode .ach-card.not-done img{
    filter:  brightness(0) invert(1) brightness(.7);
}

.ach-card h4 {
    filter: brightness(.7);
}

.light-mode .ach-card h4 {
    color: grey;
}

.light-mode .ach-card.not-done h4 {
    color: white;
    filter: brightness(.7);
}

.light-mode .ach-card h3 {
    color: black;
}

@media screen and (max-width: 800px) {

    .ach-card {
        width: 15rem;
    }
    
    .ach-card h3 {
        font-size: 1.1rem;
    }

    .ach-card h4 {
        font-size: 1rem;
    }
}

@media screen and (max-width: 550px) {

    #ach-header-navbar-toogle {
        width: 18rem;
    }

    .ach-card {
        width: 100%;
    }
}