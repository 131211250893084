html {
    background-image: radial-gradient(at bottom center, #281e44, #0d0e11);
}

html:has(body.light-mode) {
    background: #DDD;
}

#agent,
#company {
    background-image: radial-gradient(at bottom center, #281e44, #0d0e11);
    z-index: 2;
}

.light-mode #agent,
.light-mode #company {
  background: #DDD;
}
