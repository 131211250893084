#project-info {
    grid-area: project-info;
}

#vacancy-info {
    grid-area: vacancy-info;
}

#subscribe-info {
    grid-area: subscribe-info;
}

#recommended-courses {
    grid-area: courses;
}

#project-page-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 
        "vacancy-info subscribe-info project-info"
}


#project-page-cards.non-vacancy {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
        "subscribe-info project-info"
}

#project-page-courses-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 1250px) {
    #project-page-cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 1rem;
        grid-template-areas: 
            "vacancy-info subscribe-info"
            "project-info project-info";
    }
    
    #project-page-cards.non-vacancy {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
            "subscribe-info project-info"
    }

    #subscribe-info {
        max-width: 100%;
        width: 100%;
    }

    #project-info > div {
        width: 100%;
    }
}

@media screen and (max-width: 1000px) {
    #propjects-header-level {
        display: none;
    }

    #project-page-cards {
        display: grid;
        grid-template-areas: 
            "subscribe-info subscribe-info"
            "vacancy-info vacancy-info"
            "project-info project-info";
    }

    #project-page-cards.non-vacancy {
        display: flex;
        flex-direction: column-reverse;
    }

    #project-page-courses {
        padding-left: 0;
    }

    #project-page-courses-list {
        justify-content: center;
    }

    #subscribed-project-info {
        flex-direction: column;
    }
}

@media screen and (max-width: 1000px) {
    #project-info {
        flex-direction: column;
    }

    #project-info > div:nth-child(2) > div:nth-child(2) {
        display: flex;
        flex-direction: row;
    }

    #eval-project-page-info {
        flex-direction: column;
    }

}

@media screen and (max-width: 800px) {

    .project-card {
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    #project-page-courses > h1 {
        text-align: center;
    } 
}

@media screen and (max-width: 600px) {
    #vacancy-info {
        height: auto;
    }
    
    #vacancy-info > div:nth-child(3) {
        flex-direction: column;
    } 

    #project-info > div:nth-child(1) {
        min-width: auto;
    }
}

@media screen and (max-width: 500px) {
    #project-info > div:nth-child(2) > div:nth-child(2) {
        display: flex;
        flex-direction: column;
    }
    
    .subscribed-project-info-cards,
    .eval-project-page-info-cards {
        flex-direction: column;
    }

    #eval-project-page-info > div {
        height: auto;
    }
}

@media screen and (max-width: 450px) {
    .link {
        font-size: .8rem;
    }

    #project-info-btns,
    #subscribed-project-info-btns {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #project-info-desc {
        font-size: .8rem;
    }

    #vacancy-info h2 {
        font-size: 1.5rem;
    }

    .project-card h3 {
        font-size: 1rem;
    }
}