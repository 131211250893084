.circle-background {
    fill: none;
    stroke: #110f24;
}

.light-mode .circle-background {
    stroke: #a0a0a066;
}

.circle-progress {
    fill: none;
    stroke: var(--stats);
    stroke-linecap: round;
    stroke-linejoin: round;
}

.circle-text {
    font-size: 1.5rem;
    font-weight: bold;
    filter: invert();
}

.light-mode .circle-text {
    filter: none;
}

