#prompt {
    background-color: #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, .5);
}

.light-mode #prompt {
    background-color: #666;
}

@media screen and (max-width: 800px) {
    #prompt {
        width: 80%;
    }

    #prompt-content {
        width: 90%;
    }
}

@media screen and (max-width: 450px) {

    #prompt-content button {
        width: 100%;
    }
}