.courses-card {
    position: relative;
    width: 20rem;
    height: 12rem;
    border: none;
}

.courses-card img {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
}

.courses-list-grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

#header-navbar-toogle {
    width: 25rem;
    height: 3rem;
    border-radius: 100vw;
    border: none;
    
}

.courses-navbar-link {
    width: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.courses-navbar-link.selected .link {
    color: white;
}

.courses-navbar-link.selected .link {
    border-radius: 18px;
}


#toogle-selected-box {
    transition: all 300ms linear;
}

#toogle-selected-box.first {
    transform: translateX(-48%);
}

#toogle-selected-box.second {
    transform: translateX(48%);
}

@media screen and (max-width: 800px) {

    .courses-card {
        width: 15rem;
    }
}

@media screen and (max-width: 550px) {


    #projects-header-navbar-toogle {
        width: 100%;
    }

    .courses-card {
        width: 100%;
    }
}