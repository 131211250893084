/*  Circles  */

.circle {
    border-radius: 50%;
    overflow: hidden;
}

.inner-circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid transparent;
    animation: rotateGradient 1500ms linear infinite;
}

.inner-circle.red {
    background: 
        linear-gradient(#171d2b, #171d2b) padding-box,
        linear-gradient(to right bottom, red, purple) border-box;
}

.inner-circle.blue {
    background: 
        linear-gradient(#171d2b, #171d2b) padding-box,
        linear-gradient(to right bottom, blue, purple) border-box;
}

.inner-circle.yellow {
    background: 
        linear-gradient(#171d2b, #171d2b) padding-box,
        linear-gradient(to right bottom, orange, yellow) border-box;
}

/*  Triangles  */

.inner-triangle {
    width: 100%;
    height: 100%;
    border: 5px solid transparent;
}

.inner-triangle.red {
    background: 
        linear-gradient(#171d2b, #171d2b) padding-box,
        linear-gradient(to left bottom, red, purple) border-box;
    transform: rotate(-240deg);
}

.inner-triangle.purple {
    background: 
        linear-gradient(#171d2b, #171d2b) padding-box,
        linear-gradient(to left bottom, rgb(66, 56, 135), purple) border-box;
    transform: rotate(-200deg);
}

/*  Squares  */


.inner-square {
    width: 100%;
    height: 100%;
    border: 5px solid transparent;
}

.inner-square.red {
    background: 
        linear-gradient(#171d2b, #171d2b) padding-box,
        linear-gradient(to left bottom, red, purple) border-box;
    transform: rotate(-240deg);
}

.inner-square.purple {
    background: 
        linear-gradient(#171d2b, #171d2b) padding-box,
        linear-gradient(to left bottom, rgb(66, 56, 135), purple) border-box;
    transform: rotate(-200deg);
}

.inner-square.green {
    background: 
        linear-gradient(#171d2b, #171d2b) padding-box,
        linear-gradient(to left bottom, rgb(56, 135, 70), rgb(0, 107, 128)) border-box;
    transform: rotate(-100deg);
    animation: rotate 20000ms infinite linear;
}


@keyframes rotateGradient {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(-50deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
