.heming {
    font-family: Heming, sans-serif;
}

.light-mode #agent,
.light-mode #company {
  background-image: none;
  background-color: #DDD;
}

.hr {
  height: 1px;
  background-color: rgba(255,255,255,.5);
}

.light-mode .hr {
  height: 1px;
  background-color: rgba(0,0,0,.5);
}

.card {
  background-image: linear-gradient(to right bottom, var(--card-one), var(--card-two));
  border-radius: 18px;
  box-shadow: 0px 3px 6px var(--card-border-top) inset;
}

.invert-card {
  background-image: linear-gradient(to right bottom, var(--card-one), var(--card-two));
  border-radius: 18px;
  box-shadow: 0px -3px 6px var(--card-border-top) inset;
}

.light-mode .invert-card {
  background-image: linear-gradient(to right bottom, var(--card-one), var(--card-two));
  border-radius: 18px;
  box-shadow: 3px 3px 6px var(--card-border-bottom) inset, -3px -3px 6px var(--card-border-top) inset;
}

.light-mode .card {
  background-image: linear-gradient(to right bottom, var(--card-one), var(--card-two));
  border-radius: 18px;
  box-shadow: 3px 3px 6px var(--card-border-bottom), -3px -3px 6px var(--card-border-top);
}

.card,
.invert-card {
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}

.card::-webkit-scrollbar,
.invert-card::-webkit-scrollbar {
  width: 0px;
}

.card::-webkit-scrollbar-thumb,
.invert-card::-webkit-scrollbar-thumb {
  border-radius: 100vw;
  background: var(--yellow);
}

.card::-webkit-scrollbar-track,
.invert-card::-webkit-scrollbar-track {
  border-radius: 100vw;
  background: grey;
}

.card:hover::-webkit-scrollbar,
.invert-card:hover::-webkit-scrollbar {
  width: 10px;  
}

#toggle-button {
  border-radius: 100vw;
}