@media screen and (max-width: 950px) {
    #terms {
        padding-inline: 5rem;
    }
}

@media screen and (max-width: 500px) {
    #terms {
        padding-inline: 2rem;
    }

    #terms h1 {
        font-size: 1.8rem;
    }
}