#agent-settings-all > div:nth-child(1) {
    border-bottom: solid 3px rgb(190, 190, 190);
}

#profile-settings input,
#profile-settings select {
    position: relative;
    background-color: transparent;
    border-radius: 5px;
    border: solid 1.5px grey;
    padding: 5px;
    outline: none;
    width: 100%;
}

#settings-personal-info p {
    width: 50%;
}

#profile-settings img {
    border: solid 2px white;
    border-radius: 100vw;
}

.settings-navbar-link {
    list-style: none;
    transition: all 75ms ease-in;
}

.settings-navbar-link:hover {
    border-bottom: solid 4px #1B75F8;
}

.settings-navbar-link.selected {
    border: solid 4px #1B75F8;
}

#settings-personal-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

@media screen and (max-width: 1000px) {
    #settings-personal-info {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 850px) {
    #cv-settings .settings-cv-info {
        flex-direction: column;
    }

    #cv-settings-skills {
        width: 100%;
    }
}

@media screen and (max-width: 700px) {

    #agent-settings-all {
        padding-inline: 0;
    }
    #profile-settings {
        padding-right: 0;
    }

    #menu-btn {
        display: flex;
    }
}

@media screen and (max-width: 500px) {
    #settings-navbar {
        justify-content: center;
        align-items: center;
        gap: .7rem;
    }

    #profile-settings {
        padding: 0;
    }

    #profile-settings-img {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    #profile-settings-img h2 {
        margin: 0;
        margin-top: 1rem;
    }

    #settings-personal-info {
        padding-left: 2rem;
    }

    #settings-personal-info > div {
        margin-right: 1rem;
    }
    
    #profile-settings h1 {
        text-align: center;
    }

    #profile-settings-btn {
        justify-content: center;
    }

    .settings-security-info > div {
        flex-direction: column;
    }

    #settings-personal-info.settings-security-info p {
        width: 100%;
        text-align: left;
    }
}

@media screen and (max-width: 450px) {
    #settings-navbar {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}


@media screen and (max-width: 350px) {
    #settings-personal-info {
        padding-left: 0;
    }

    #settings-personal-info > div {
        margin-right: 0;
    }
}