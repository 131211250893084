#header {
    width: 100%;
    grid-area: header;
}

#header-navbar {
    height: 5.5rem;
    width: 100%;
}

#header-profile {
    width: 100%;
}

#header-navbar-search {
    width: 12rem;
    height: 3rem;
    padding: 1rem;
    padding-right: 0;
    border: none;
    border-radius: 100vw;
}

#header-navbar-search ~ h1 {
    color: grey;
}

#header-navbar-search img {
    filter: invert(1) brightness(60%);
}

#header-navbar-search input:focus ~ img {
    filter: invert(1);
}

.light-mode #header-navbar-search input:focus ~ img {
    filter: brightness(0);
}

#header-navbar-search input:focus{
    border-radius: 0 18px 18px 0;
}

#header-navbar-search input {
    padding: .7rem;
    background-color: transparent;
    outline: none;
}

#header-navbar-notifications {
    width: 2rem;
    height: 2rem;
    border-radius: 15px;
}

#header-navbar-notifications img {
    transition: all 200ms linear;
}

#header-navbar-notifications:hover img {
    filter: brightness(.6);
}

#header-navbar-foto {
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 100vw;
    background-color: var(--stats-bg);
}

.light-mode #header-profile-contact-info img {
    filter: brightness(0) invert(1) brightness(.6);
}

#header-profile-personal-info {
    grid-area: personal;
    width: 100%;
}

#header-profile-dashboard-info {
    grid-area: cards;
}

#header-img {
    grid-area: profile;
}

#header-profile-box {
    background-color: var(--stats-bg);
    border-radius: 18px;
}

.light-mode #header-profile-box {
    background-color: white;
    box-shadow: 3px 3px 6px var(--card-border-bottom) inset, -3px -3px 6px var(--card-border-top) inset;
}

#header-profile-level {
    z-index: 0;
    width: 37%;
    border-radius: 18px 0px 0px 18px;
    animation: grow-level 1000ms forwards;
}

@keyframes grow-level {
    0% {
        width: 0%;
    }
}

#header-profile-level ~ p {
    z-index: 1;
}

.header-card.points {
    background-image: linear-gradient(to right bottom, rgba(112, 191, 255, 0.7), rgba(0, 82, 148, 0.7));
    border-radius: 18px;
    transition: all 200ms linear;
}

.header-card.days {
    background-image: linear-gradient(to right bottom,rgba(255, 178, 116, 0.7), rgba(132, 59, 0, 0.7));
    border-radius: 18px;
    transition: all 200ms linear;
}

.info-card {
    width: 10rem;
    height: 7rem;
    border: none;
    box-shadow: 0px -3px 6px var(--card-border-top) inset;
}


.light-mode .info-card {
    box-shadow: 3px 3px 6px var(--card-border-bottom) inset, -3px -3px 6px var(--card-border-top) inset;
}

#menu-btn {
    cursor: pointer;
    transition: all 0.35s ease-in-out;
  }
  
  .menu-btn-bar {
    transition: all 0.35s ease-in-out;
  }
  
  /* Styles when menu is active */
  #menu-btn.active .menu-btn-bar:nth-child(1) {
    transform: rotate(45deg) translate(0.35rem, 0.35rem);
  }
  
  #menu-btn.active .menu-btn-bar:nth-child(2) {
    opacity: 0;
  }
  
  #menu-btn.active .menu-btn-bar:nth-child(3) {
    transform: rotate(-45deg) translate(0.35rem, -0.35rem);
  }
  
  

@media screen and (min-width: 800px) and (max-width: 1100px) {
    #header-profile {
        padding-left: 3rem;
        padding-right: 1rem;
    }

    #header-profile-dashboard-info {
        flex-direction: column;
        width: 50%;
    }

    #header-profile-dashboard-info img {
        width: 7rem;
    }

    .info-card {
        width: 8rem;
        height: 7rem;
    }

    .info-card p {
        font-size: .9rem;
    }

    .info-card h2 {
        font-size: 1rem;
    }


    
}


@media screen and (max-width: 800px) {

    #header-profile {
        padding-inline: 2rem;
        gap: 1rem;
    }
    
    #header-profile-personal-info,
    #header-profile-dashboard-info,
    #header-img {
        width: 100%;
        align-self: center;
        justify-self: center;
        justify-content: center;
        align-items: center;
        text-align: center;
    }


    
    #header-img {
        width: 10rem;
    }

    #header-profile-dashboard-info > div {
        flex-direction: column;
    }
    
    #header-profile.card {
        padding-top: 1rem;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas: 
        "profile"
        "personal"
        "cards";
        justify-content: center;
        align-items: center;
        min-height: 100vh; /* Set minimum height */
        overflow-y: auto;  /* Enable vertical scrolling */
    }

    #project-header #header-profile.card {
        padding-top: 1rem;
        display: grid;
        grid-template-rows: 1fr 1fr ;
        grid-template-areas: 
        "personal"
        "cards";
        justify-content: center;
        align-items: center;
        min-height: 100vh; /* Set minimum height */
        overflow-y: auto;  /* Enable vertical scrolling */
    }

    .info-card {
        margin: 0;
        margin-right: 0;
    }

    #project-header #header-profile-dashboard-info {
        flex-direction: column;
    }
}




@media screen and (max-width: 650px) {



}

@media screen and (max-width: 500px) {
    .info-card {
        width: 100%;
        height: 7rem;
    }

    #header-navbar-search {
        width: 100%;
    }

    #header-profile-dashboard-info {
        flex-direction: column;
    }

    #header-profile-box {
        width: 100%;
    }
}

