.projects-page-title {
    padding-right: 1rem;
    border-right: solid 5px white;
}

.projects-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.project-card {
    position: relative;
    width: 20rem;
    height: 8rem;
}

.project-card-top {
    height: 75%;
    border-radius: 18px 18px 0 0;
}

.project-card-image-box {
    background-color: rgba(33, 152, 188, 0.8);
    border-radius: 18px;
}

.project-card-settings div {
    background-color: grey;
    width: 5px;
    height: 5px;
    border-radius: 100vw;
    margin: 2px;
}

.project-card h5 {
    color: var(--soft-text);
}
.light-mode .poject-page-card {
    box-shadow: 3px 3px 6px var(--card-border-bottom) inset, -3px -3px 6px var(--card-border-top) inset;
}

.project-card-line {
    background-image: linear-gradient(to right, transparent, var(--text), transparent);
    width: 100%;
    height: 1px;
}

.project-card-bottom {
    height: 25%;
    border-radius: 0 0 25px 25px;
}

#project-subject {
    background-color: #1B75F8;
    border-radius: 10px;
    border: solid 1px transparent;
    transition: all 200ms ease-in;
    color: white;
}

#project-subject:hover {
    border: solid 1px white;
}

#company-projects-header-navbar-toogle {
    width: 30rem;
    height: 3rem;
    border-radius: 100vw;
    background-color: rgba(0,0,0,.5);
    border: none;
}

.company-projects-navbar-link {
    width: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.link {
    color: rgb(154, 154, 154);
    padding: 5px;
}


.company-projects-navbar-link:hover .link,
.company-projects-navbar-link:focus .link {
    color: var(--text);
}

.company-projects-navbar-link.selected .link {
    color: white;
}

.company-projects-navbar-link.selected .link {
    border-radius: 18px;
}

#company-toogle-selected-box {
    transition: all 300ms linear;
}

#company-toogle-selected-box.first {
    transform: translateX(-48%);
}

#company-toogle-selected-box.second {
    transform: translateX(48%);
}

#give-up-btn {
    background-color: rgb(255, 52, 52);
    color: white;
}

#done-project-btn {
    background-color: rgb(0, 162, 16);
    color: white;
}

#vertical-line {
    background-color: var(--text);
}

#sub-project-deadline-card {
    box-shadow: 0px -3px 6px var(--card-border-top) inset;
}

.light-mode #sub-project-deadline-card {
    box-shadow: 3px 3px 6px var(--card-border-bottom) inset, -3px -3px 6px var(--card-border-top) inset;
}


#done-project-page #git-repo-box {
    filter: brightness(.5);
}

#project-info {
    grid-area: project-info;
}

#vacancy-info {
    grid-area: vacancy-info;
}

#subscribe-info {
    grid-area: subscribe-info;
}

#project-page-courses-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 1250px) {

    #project-info > div {
        width: 100%;
    }
}

@media screen and (max-width: 1100px) {
    #propjects-header-level {
        display: none;
    }

    #project-page-courses {
        padding-left: 0;
    }

    #project-page-courses-list {
        justify-content: center;
    }

    #subscribed-project-info {
        flex-direction: column;
    }
}

@media screen and (max-width: 1000px) {
    #project-info {
        flex-direction: column;
    }

    #project-info > div:nth-child(2) > div:nth-child(2) {
        display: flex;
        flex-direction: row;
    }

    #eval-project-page-info {
        flex-direction: column;
    }

}

@media screen and (max-width: 800px) {

    .project-card {
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    #project-page-courses > h1 {
        text-align: center;
    } 
}

@media screen and (max-width: 600px) {
    #vacancy-info {
        height: auto;
    }
    
    #vacancy-info > div:nth-child(3) {
        flex-direction: column;
    } 

    #project-info > div:nth-child(1) {
        min-width: auto;
    }

    #edit-project-project-info,
    #edit-project-vacancy-info {
        flex-direction: column;
    }

    #edit-project-project-info > div,
    #edit-project-vacancy-info > div {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    #project-info > div:nth-child(2) > div:nth-child(2) {
        display: flex;
        flex-direction: column;
    }
    
    .subscribed-project-info-cards,
    .eval-project-page-info-cards {
        flex-direction: column;
    }

    #eval-project-page-info > div {
        height: auto;
    }
}

@media screen and (max-width: 450px) {
    .link {
        font-size: .8rem;
    }

    #project-info-btns,
    #subscribed-project-info-btns {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #project-info-desc {
        font-size: .8rem;
    }

    #vacancy-info h2 {
        font-size: 1.5rem;
    }
}