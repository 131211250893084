#projects-header {
    width: 100%;
}

#projects-header-top {
    height: 5.5rem;
    width: 100%;
}

#projects-header-top-notifications {
    width: 2rem;
    height: 2rem;
    border-radius: 15px;
}

#projects-header-top-notifications img {
    filter: invert(1) brightness(.6);
    transition: all 200ms linear;
}

#projects-header-top-notifications:hover img {
    filter: invert(1);
}

#projects-header-top-foto {
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 100vw;
    background-color: rgba(0,0,0,.5);
}






#projects-header-stats {
    height: 17.5rem;
    width: 100%;
    background-color: rgba(0,0,0,.5);
    border-radius: 18px;
}

#projects-projects-stats {
    width: 100%;
    height: 100%;
}

#projects-projects-stats-title {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    writing-mode: vertical-rl;
    transform: scale(-1);
}

#projects-projects-stats-outer-box {
    width: 18rem;
    height: 85%;
}

#projects-projects-stats-box {
    width: 100%;
    height: 100%;
    border-left: solid 1px grey;
    border-bottom: solid 1px grey;
}

.projects-stats-line {
    width: 2rem;
    animation: grow-line-vertical 1000ms forwards;
}

@keyframes grow-line-vertical {
    0% {
        height: 0%;
    }
}


#projects-level-out-circle {
    position: relative;
    margin: 0 auto;
}

#projects-level-out-circle::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: solid 15px rgba(0,0,0,.5);
    position: absolute;
    top: 0;
    left: 0;
}

#projects-level-out-circle > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}

.projects-level-in-circle.left {
    left: 0;
}

.projects-level-in-circle.left .projects-level-progress {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    transform-origin: center left;
    -webkit-transform-origin: center left;
    animation: loading-2 500ms linear forwards 500ms;
}

.projects-level-progress {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 15px;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: #19D7E2;
}

.projects-level-in-circle.right {
    right: 0;
}

.projects-level-in-circle.right .projects-level-progress{
    left: -100%;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    border-right: 0;
    transform-origin: center right;
    -webkit-transform-origin: center right;
    animation: loading-1 500ms linear forwards;
}

.projects-level-value  {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@keyframes loading-1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(133.2deg);
        -webkit-transform: rotate(133.2deg);
    }
}

@keyframes loading-2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}




#projects-projects-to-dos {
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

#projects-projects-to-dos::-webkit-scrollbar {
    display: none;
}


.to-do {
    height: 5rem;
    border-left: solid 5px;
    border-radius: 18px;
    margin-bottom: 1rem;
    padding: 0.5rem;
    padding-left: 1rem;
    color: var(--text);
    box-shadow: 0px -3px 6px var(--card-border-top) inset;
}

.to-do h4 {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.light-mode .to-do {
    box-shadow: 3px 3px 6px var(--card-border-bottom) inset, -3px -3px 6px var(--card-border-top) inset;
}

.to-do.red {
    border-color: red;
}

.to-do.yellow {
    border-color: yellow;
}

.to-do.blue {
    border-color: blue;
}

.to-do input {
    accent-color: #1B75F8;
}











#projects-header-navbar {
    width: 100%;
    margin-top: 1rem;
}

#projects-header-filters {
    width: 5rem;
    height: 3rem;
    border-radius: 18px;
    background-color: rgba(0,0,0,.5);
    margin-right: 1rem;
    filter: brightness(.7);
}

#projects-header-filters:hover,
#projects-header-filters:focus {
    filter: none;
}

#projects-header-navbar-search {
    width: 100%;
    height: 3rem;
    border-radius: 18px;
    padding: 1.5rem;
    padding-right: 0;
    background-color: rgba(0,0,0,.5);
    margin-right: 1rem;
}

#projects-header-navbar-search img {
    filter: invert(1) brightness(60%);
}

#projects-header-navbar-search input:focus ~ img {
    filter: invert(1);
}

#projects-header-navbar-search input:focus{
    border: solid 1px white;
    border-radius: 0 18px 18px 0;
}

#projects-header-navbar-search input {
    padding: .7rem;
    background-color: transparent;
    outline: none;
}

@media screen and (min-width: 1100px) {

    #projects-header-userstats {
        position: absolute;
        width: 20%;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}


@media screen and (max-width: 1100px) {
    #projects-header-evaluations {
        display: none;
    }

    #projects-header-userstats,
    #projects-header-chart {
        position: relative;
        width: 100%;
    }
    
}

@media screen and (max-width: 800px) {
    #projects-header-stats {
        min-height: 20rem;
    }


    #projects-header-userstats {
        display: none;
    }

}

@media screen and (max-width: 500px) {
    #projects-header {
        display: none;
    }
}
