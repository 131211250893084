
#login-container {
    width: 18rem;
    height: 18rem;
}

.login-input {
    margin-bottom: 1rem;
    background-color: transparent;
    outline: none;
    border-bottom: solid 1px rgb(200, 200, 200);
    padding-bottom: 5px;
    padding-left: 5px;
    font-size: 1rem;
}

.login-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(202, 202, 202);
    opacity: 1; /* Firefox */
}

#login-btn {
    width: 4rem;
    height: 2rem;
    border: solid 1px white;
    border-radius: 5px;
    transition: all 150ms ease-in;
}

#login-btn:hover,
#login-btn:focus {
    background-color: var(--blue);
}

#login-remeber-checkbox {
    accent-color: var(--blue);
}

#login-register-message {
    background-image: linear-gradient(to right, transparent, white, transparent);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-top: 1rem;
}