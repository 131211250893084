#header-search {
    width: 12rem;
    height: 3rem;
    padding: 1.5rem;
    padding-right: 0;
    border: none;
    border-radius: 100vw;
}

#header-navbar {
    height: 5.5rem;
    width: 100%;
}

#header-filters {
    width: 7rem;
    height: 3rem;
    border: none;
    border-radius: 100vw;
}

#header-search {
    width: 15rem;
    height: 3rem;
    padding: 1.5rem;
    padding-right: 0;
    border: none;
    border-radius: 100vw;
    transition: all 400ms ease-in;
}

#header-cv {
    border-radius: 100vw;
}

#header-search:has(input:focus) {
    width: 100%;
}

#header-search img {
    filter: invert(1) brightness(.6);
}

#header-search input:focus ~ img {
    filter: invert(1);
}

.light-mode #header-search input:focus ~ img {
    filter: brightness(0);
}

#header-search input:focus{
    border-radius: 0 18px 18px 0;
}

#header-search input {
    padding: .7rem;
    background-color: transparent;
    outline: none;
}

#header-search input::placeholder {
    font-size: .9rem;
}


.light-mode #header-search input::placeholder {
    padding: .7rem;
    background-color: transparent;
    outline: none;
    color: black;
}

#header-filters p,
#header-filters div {
    filter: brightness(.8);
    color: rgb(171, 171, 171);
}

#header-filters:hover p,
#header-filters:hover div {
    filter: none;
}

.light-mode #header-filters:hover div,
.light-mode #header-filters:focus div {
    filter: brightness(0);
}

#header-search img {
    filter: invert(1) brightness(60%);
}

#header-search input:focus ~ img {
    filter: invert(1);
}

#header-search input:focus{
    border-radius: 0 18px 18px 0;
}

#header-search input {
    padding: .7rem;
    background-color: transparent;
    outline: none;
}

#header-notifications {
    width: 2rem;
    height: 2rem;
    border-radius: 15px;
}

#header-notifications img {
    transition: all 200ms linear;
}

#header-notifications:hover img {
    filter: brightness(.6);
}

#header-foto {
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 100vw;
    background-color: rgba(0,0,0,.5);
}

#header-profile-box {
    background-color: var(--stats-bg);
    border-radius: 18px;
}

#menu-btn  {
    display: none;
}

#filters-menu {
    box-shadow: 5px 5px 20px 0 rgba(0,0,0,.5);
    opacity: 0;
    animation: fade-in 400ms forwards;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

#filters-menu::before {
    content: '';
    position: absolute;
    top: -.5rem;
    left: 2rem;
    width: 1rem;
    height: 1rem;
    background-color: var(--card-one);
    transform: rotate(45deg);
}

@media screen and (max-width: 700px) {

    #header-filters {
        width: 40%;
    }

    #header-search {
        width: 100%;
    }

    #header-top-right {
        display: none;
    }

    #menu-btn {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 450px) {
    #header-filters {
        width: 60%;
    }

    #header-search {
        width: 100%;
    }

    #filters-menu {
        width: calc(100vw - 2rem);
        box-shadow: 0px 10px 20px rgba(0,0,0,.5);
    }
}