#support-form input,
#support-form textarea {
    margin-bottom: 1rem;
    background-color: transparent;
    outline: none;
    border-bottom: solid 1px rgb(200, 200, 200);
    padding-bottom: 5px;
    padding-left: 5px;
    font-size: 1rem;
    resize: none;
}

#support-form textarea {
    border: solid 2px rgb(200, 200, 200);
    border-radius: 18px;
    padding: 1rem;
}

#support-form input::placeholder,
#support-form textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(202, 202, 202);
    opacity: 1; /* Firefox */
}

@media screen and (max-width: 400px) {
    #support {
        padding-inline: 2rem;
    }

    #support-form {
        width: 100%;
    }
}