#signup-container {
    width: 18rem;
}

.signup-input[type=password]:not(:placeholder-shown) {
    font-family: Verdana;
}

.signup-input[type=password]::-ms-reveal,
.signup-input[type=password]::-ms-clear {
    display: none;
}

.signup-input {
    margin-bottom: 1rem;
    background-color: transparent;
    outline: none;
    border-bottom: solid 1px rgb(200, 200, 200);
    padding-bottom: 5px;
    padding-left: 5px;
    font-size: 1rem;
}

#signup-btn {
    width: 7rem;
    height: 2.2rem;
    border: solid 1px white;
    border-radius: 100vw;
    transition: all 150ms ease-in;
}

#signup-btn:hover,
#signup-btn:focus {
    background-color: var(--blue);
}

#signup-remeber-checkbox {
    accent-color: var(--blue);
}

#signup-register-message {
    background-image: linear-gradient(to right, transparent, white, transparent);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-top: 1rem;
}

.sign-in-button {
    width: 8.5rem;
    border: solid 1px white;
    border-radius: 100vw;
    padding: 6px;
    cursor: pointer;
    transition: all 100ms ease-in;
}

.sign-in-button.active {
    background-color: white;
    color: var(--blue);
}

#it-selector {
    position: relative;
    width: 100%;
    height: 1.7rem;
    background: transparent;
    border-radius: 5px;
    border: solid 2px rgb(178, 178, 178);
    outline: none;
}

#it-selector * {
    background-color: var(--blue);

}

#it-selector *:hover {
    background-color: var(--blue);
    
}