@media screen and (max-width: 950px) {
    #footer a,
    #footer p {
        font-size: .8rem;
    }

    #footer > div {
        gap: 2rem;
    }
}


@media screen and (max-width: 800px) {
    #footer {
        flex-direction: column-reverse;
    }

    #footer > div {
        width: 100%;
        justify-content: center;
    }
}

@media screen and (max-width: 700px) {

    #footer {
        padding-inline: 5vw;
    }

    #footer > :nth-child(2) p,
    #footer > :nth-child(2) a {
        font-size: 1rem;
    }
}

@media screen and (max-width: 400px) {

    #footer > :nth-child(1) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

