@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Garet-Book";
  src: url('../content/fonts/garet/Garet-Book.ttf');
}

@font-face {
  font-family: "Objectivity";
  src: url('../content/fonts/Objectivity-Regular.ttf');
}

@font-face {
  font-family: "Heming";
  src: url('../content/fonts/Heming.ttf');
}

* {
  /* outline: 1px solid limegreen !important; */
  font-family: Objectivity, Garet-Book, sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  color: white;
}

:root {
  --card-one: #2A3246;
  --card-two: #192134;
  --card-border-top: #b6b6b629;
  --card-border-bottom: none;
  --stats: #19D7E2;
  --blue: #1B75F8;
  --yellow: #f7c940;
  --orange: #fea36b;
  --green: #3edf9f;
  --stats-bg: #0c0e35;
  --text: #FFF;
  --soft-text: rgb(192, 192, 192);
  --inverted: #000;
  --inputbg: rgba(.5,.5,.5,.3);
}

.light-mode {
  --card-one: #DDD;
  --card-two: #DDD;
  --yellow: #fea36b;
  --card-border-top: rgba(255, 255, 255, .75);
  --card-border-bottom: rgba(68, 68, 68, .16);
  --stats: #1B75F8;
  --stats-bg: #FFF;
  --text: #000;
  --soft-text: rgb(111, 111, 111);
  --inverted: #FFF;
  --inputbg: rgba(182, 182, 182, .7);
}

body::-webkit-scrollbar {
  display: none;
}

body.light-mode {
  color: black;
}

#website h1, #website h1 * {
  font-family: Garet-Book, sans-serif;
  font-weight: 600;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/* For IE */
input[type="number"]::-ms-clear {
    display: none;
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(202, 202, 202);
  opacity: 1; /* Firefox */
}

.light-mode input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

input[type="checkbox"] {
  accent-color: var(--blue);
  cursor: pointer;
}

button {
  border: solid 2px transparent;
  transition: all 200ms ease-in;
}

button:hover {
  border: solid 2px white;
}

@media screen and (max-width: 700px) {
    .page {
      padding-left: 1rem;
    }
}