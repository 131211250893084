.project-eval-btn {
    filter: grayscale(100%) brightness(150%);
    color: white;
}

.project-eval-btn.clicked {
    filter: none;
}

#project-eval-next-btns {
    position: fixed;
    top: 60%;
    right: 15%;
    transform: translate(-50%, -50%);
}

#project-eval-next-btns button {
    transition: all 300ms ease-in;
}

@media screen and (max-width: 1000px) {
    .slider-ele {
        padding-inline: 0;
    }
}

@media screen and (max-width: 600px) {
    .slider-ele h2 {
        font-size: 1rem;
    }
}