

#navbar:hover .navbar-link p {
    display: flex;
    opacity: 0;
    animation: navbar-text 900ms ease forwards;
}

@keyframes navbar-text {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 700px) {
    #navbar:hover {
        width: 13rem;
        box-shadow: 5px 0px 20px rgba(0, 0, 0, .5);
    }

    #navbar:hover #navbar-icon h2 {
        display: none;
    }

    #navbar:hover #navbar-icon img {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    #navbar {
        width: calc(100% - 2rem);
        transform: translateY(calc(-100% - 4rem));
    }

    #navbar.active {
        transform: translateY(0);
    }
    #navbar.active #navbar-icon h2 {
        display: none;
    }

    #navbar.active #navbar-icon img {
        display: flex;
    }

    #navbar.active .navbar-link p {
        display: flex;
    }

}