#company-profile {
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr 1fr 1fr; /* Adjust according to your content */
    grid-template-columns: 1fr 1fr 1fr 1fr; /* Creates three equally sized columns */
    grid-template-areas: 
        "header header header header"
        "calendar calendar stats stats"
        "vacancy vacancy members members"
        "projects projects projects projects";
    gap: 1rem;
    margin-bottom: 1rem;
}

#company-other-profile {
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr 1fr; /* Adjust according to your content */
    grid-template-columns: 1fr 1fr 1fr 1fr; /* Creates three equally sized columns */
    grid-template-areas: 
        "header header header header"
        "vacancy vacancy stats stats"
        "projects projects projects projects";
    gap: 1rem;
    margin-bottom: 1rem;
}

@media screen and (max-width: 1050px) {
    
    #company-profile {
        grid-template-rows: auto 1fr 1fr 1fr 1fr; /* Adjust according to your content */
        grid-template-columns: 1fr 1fr 1fr; /* Creates three equally sized columns */
        grid-template-areas: 
        "header header header"
        "stats stats stats"
        "calendar calendar calendar"
        "vacancy vacancy vacancy"
        "members members members"
        "projects projects projects";
    }

    #company-other-profile {
        position: relative;
        display: grid;
        grid-template-rows: auto 1fr 1fr; /* Adjust according to your content */
        grid-template-columns: 1fr 1fr 1fr; /* Creates three equally sized columns */
        grid-template-areas: 
            "header header header"
            "stats stats stats"
            "vacancy vacancy vacancy"
            "projects projects projects";
        gap: 1rem;
        margin-bottom: 1rem;
    }

}

