#evaluation-card-container {
    grid-area: eval;
}

#evaluations-card {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.eval-item {
    box-shadow: 0px -3px 6px var(--card-border-top) inset;
}

.light-mode .eval-item {
    box-shadow: 3px 3px 6px var(--card-border-bottom) inset, -3px -3px 6px var(--card-border-top) inset;
}

.eval-item h1 {
    transform: translateX(-1rem);
    transition: all 200ms ease-in;
}

.eval-item h2 {
    font-size: 80%;
    color: #f7c940;
}

.light-mode .eval-item h2 {
    color: #ff6f17;
}

.eval-item:hover h1,
.eval-item:focus h1 {
    transform: translateX(0rem);
    color: #f7c940;
}

.light-mode .eval-item:hover h1,
.light-mode .eval-item:focus h1 {
    transform: translateX(0rem);
    color: #ff6f17;
}

#card-skills {
    width: 100%;
    height: 20rem;
    margin-right: 1rem;
    overflow-x: hidden;
    grid-area: skills;
}

#skills-card-progress-bg {
    box-shadow: 3px 3px 6px var(--card-border-bottom) inset, -3px -3px 6px var(--card-border-top) inset;
}

.it-skill-line {
    animation: grow-line 1000ms forwards;
}

@keyframes grow-line  {
    0% {
        width: 0%;
    }
}

#card-stats {
    width: 100%;
    height: 20rem;
    grid-area: stats;
    
}

#card-achievements {
    width: 100%;
    height: 20rem;
    overflow-x: hidden;
    margin-right: 1rem;
    grid-area: achiev;
}

#card-done-projects {
    width: 100%;
    height: 20rem;
    overflow-x: hidden;
    grid-area: projects;
}

.done-project:nth-child(1) {
    margin-left: 0;
}

.done-project {
    width: 10rem;
    height: 13rem;
    border-radius: 18px;
}

.done-project:nth-child(2n + 1) {
    background-image: linear-gradient(to right bottom, #222AE7, #54A2B1);
}

.done-project:nth-child(2n) {
    background-image: linear-gradient(to right bottom, #161E4F, #2631EF);
}

.done-project:nth-child(3n) {
    background-image: linear-gradient(to right bottom, #005B88, #19D7E2);
}

.done-project-image-tech {
    background-color: rgb(0,0,0,.3);
    border-radius: 10px;
}

.done-project-top {
    height: 75%;
    border-radius: 18px 18px 0 0;
}

.done-project-bottom {
    height: 25%;
    border-radius: 0 0 18px 18px;
}

#card-stats-out-circle {
    position: relative;
    margin: 0 auto;
}

#card-stats-out-circle::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: solid 15px var(--stats-bg);
    position: absolute;
    top: 0;
    left: 0;
}

.light-mode #card-stats-out-circle::after {
    border: none;
    box-shadow: 3px 3px 6px var(--card-border-bottom) inset, -3px -3px 6px var(--card-border-top) inset;
}

#card-stats-out-circle > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}

.card-stats-in-circle.left {
    left: 0;
}

.card-stats-in-circle.left .card-stats-progress {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    transform-origin: center left;
    -webkit-transform-origin: center left;
    animation: loading-2 500ms linear forwards 500ms;
}

.card-stats-progress {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 15px;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: var(--stats);
}

.card-stats-in-circle.right {
    right: 0;
}

.card-stats-in-circle.right .card-stats-progress{
    left: -100%;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    border-right: 0;
    transform-origin: center right;
    -webkit-transform-origin: center right;
    animation: loading-1 500ms linear forwards;
}

.card-stats-value  {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@keyframes loading-1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(133.2deg);
        -webkit-transform: rotate(133.2deg);
    }
}

@keyframes loading-2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

#more-projects-card {
    border: solid 1px grey;
    width: 10rem;
    height: 13rem;
    border-radius: 18px;
}

#button-more-projects {
    background-color: #2A3246;
    border-radius: 100vw;
    color: grey;
    height: 2rem;
    font-size: .9rem;
    cursor: pointer;
}

.light-mode #button-more-projects {
    background-color:#E4E4E4;
    color: black;
}

.light-mode #more-projects-card img {
    filter: brightness(0);
}

.home-project-card-line {
    background-image: linear-gradient(to right, transparent, white, transparent);
    width: 100%;
    height: 1px;
}

#card-done-projects-list {
    display: grid;
    row-gap: 2rem;
    column-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
}

#card-calendar::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 1000px) {
    #card-achievements > div {
        padding-left: 1rem;
    }
}

@media screen and (max-width: 750px) {
    #card-done-projects-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 2rem;
        column-gap: 1rem;
    }

    .done-project {
        width: 9rem;
        height: 13rem;
        border-radius: 15px;
    }

    #more-projects-card {
        width: 9rem;
        height: 13rem;
        border-radius: 15px;
        padding: 15px;
    }
}

@media screen and (max-width: 600px) {
    #card-done-projects-list {
        justify-content: center;
        align-items: center;
        grid-template-columns: 1fr 1fr;
    }

    .done-project {
        align-self: center;
        justify-self: center;
    }

    #more-projects-card {
        align-self: center;
        justify-self: center;
    }
}

@media screen and (max-width: 550px) {
    
    #card-skills {
        width: 100%;
        height: 20rem;
        margin-right: 1rem;
        overflow-x: hidden;
        grid-area: skills;
    }

    #card-skills > div {
        flex-direction: column;
    }

    #card-skills > div > div {
        width: 100%;
    }

    .eval-item-arrow {
        display: none;
    }


}

@media screen and (max-width: 400px) {
    #card-done-projects-list {
        padding-top: 2rem;
        grid-template-columns: 1fr;
    }

    .done-project {
        width: 10rem;
        height: 13rem;
        border-radius: 18px;
    }

    #more-projects-card {
        width: 10rem;
        height: 13rem;
        border-radius: 18px;
    }
}

