@media screen and (max-width:500px) {
    #card-calendar > div {
        flex-direction: column;
    }

    #card-calendar > div > div{
        flex-direction: row;
    }
}

@media screen and (max-width:425px) {

    #card-calendar > div > div:nth-child(1){
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}