#profile {
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr 1fr 1fr; /* Adjust according to your content */
    grid-template-columns: 1fr 1fr 1fr; /* Creates three equally sized columns */
    grid-template-areas: 
        "header header header"
        "calendar achiev stats"
        "skills skills eval"
        "projects projects projects";
    gap: 1rem;
    margin-bottom: 1rem;
}

#other-profile {
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr 1fr 1fr; /* Adjust according to your content */
    grid-template-columns: 1fr 1fr 1fr; /* Creates three equally sized columns */
    grid-template-areas: 
        "header header header"
        "achiev achiev stats"
        "skills skills skills"
        "projects projects projects";
    gap: 1rem;
    margin-bottom: 1rem;
}

@media screen and (max-width: 1200px) {
    
    #profile {
        grid-template-rows: auto 1fr 1fr 1fr 1fr; /* Adjust according to your content */
        grid-template-columns: 1fr 1fr 1fr 1fr; /* Creates three equally sized columns */
        grid-template-areas: 
            "header header header header"
            "calendar calendar achiev achiev"
            "stats eval eval eval"
            "skills skills skills skills"
            "projects projects projects projects";
    }

}

@media screen and (max-width: 850px) {
    
    #profile {
        grid-template-rows: auto 1fr 1fr 1fr 1fr 1fr; /* Adjust according to your content */
        grid-template-columns: 1fr 1fr 1fr; /* Creates three equally sized columns */
        grid-template-areas: 
            "header header header"
            "calendar calendar calendar"
            "achiev achiev stats"
            "eval eval eval"
            "skills skills skills"
            "projects projects projects";
    }

}

@media screen and (max-width: 550px) {
    
    #profile {
        grid-template-rows: auto 1fr 1fr 1fr 1fr 1fr 1fr; /* Adjust according to your content */
        grid-template-columns: 1fr 1fr 1fr; /* Creates three equally sized columns */
        grid-template-areas: 
            "header header header"
            "calendar calendar calendar"
            "achiev achiev achiev"
            "stats stats stats"
            "eval eval eval"
            "skills skills skills"
            "projects projects projects";
    }

    #other-profile {
        grid-template-rows: auto 1fr 1fr 1fr 1fr 1fr 1fr; /* Adjust according to your content */
        grid-template-columns: 1fr 1fr 1fr; /* Creates three equally sized columns */
        grid-template-areas: 
            "header header header"
            "achiev achiev achiev"
            "stats stats stats"
            "skills skills skills"
            "projects projects projects";
    }
    

}